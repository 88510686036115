import React, { useState, useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useSelector, useDispatch } from 'react-redux';
import { Linking } from 'react-native';


import { auth, realtimeDB } from './services/firebase';
import { setUser } from './store/UserSlice';
import { setProjectDefinition, setProjectId } from './store/ProjectSlice';

import Page from './screens/PageScreen';

const Stack = createNativeStackNavigator();

export const Navigation = () => {
    const dispatch = useDispatch();

    const [initializingProjectId, setInitializingProjectId] = useState(true);

    // Set an initializingUser state whilst Firebase connects
    const [initializingUser, setInitializingUser] = useState(true);
    const user = useSelector(state => state.user);
    const indexId = useSelector(state => state.project.definition.page_index);
    const pages = useSelector(state => state.project.definition.pages);


    const handleAuthChange = (user) => {
        dispatch(setUser(user));
        if (initializingUser) setInitializingUser(false);
    };

    const fetchProjectStructure = async () => {
        // TODO: clean up projects separation and platforms
        const url = await Linking.getInitialURL();
        const urlWithoutProtocol = url.replace(/^https?:\/\//i, '');
        const domainRoot = urlWithoutProtocol.split('/')[0].replaceAll('.', '-');
        const path = urlWithoutProtocol.split('/')[1];
        console.log('path', path); // Output: 'example.com'
        console.log(domainRoot); // Output: 'www.example.com'

        const projectId = await realtimeDB.readData(`domains/${domainRoot}`)
        dispatch(setProjectId(projectId));
        console.log('Read projectId', projectId)

        // TODO: handle multiple environments
        if (path === 'dev') {
            const projectDefinition = await realtimeDB.readData(`projects/${projectId}/dev`)
            console.log('projectDefinition', projectDefinition)
            dispatch(setProjectDefinition(projectDefinition));
        } else {
            const projectDefinition = await realtimeDB.readData(`projects/${projectId}/prod`)
            console.log('projectDefinition', projectDefinition)
            dispatch(setProjectDefinition(projectDefinition));
        }

        if (initializingProjectId) setInitializingProjectId(false);
    }

    useEffect(() => {
        return auth.onAuthStateChanged(handleAuthChange);
    }, []);

    useEffect(() => {
        if (initializingProjectId) {
            fetchProjectStructure();
        }
    }, [])

    if (initializingUser || initializingProjectId) return null;

    return <NavigationContainer>
        <Stack.Navigator screenOptions={{
            // header: () => <Header />,
            headerShown: false,
        }}>
            <Stack.Screen name={pages[indexId].name} component={Page} initialParams={{ pageId: indexId }} />
            {
                Object.values(pages)
                    .filter((page) => page?.uid && page.uid !== indexId)
                    .map((page) =>
                        <Stack.Screen key={page.uid} name={page.name} component={Page} initialParams={{ pageId: page.uid }} />
                    )
            }
        </Stack.Navigator>
    </NavigationContainer>
}
