import { Pressable, } from 'react-native'
import React from 'react'
import GUIComponent from '../GUIComponent'
import { useDispatch } from 'react-redux'

const Container = ({ definition, style }) => {
    const dispatch = useDispatch()
    return (
        <>
            {definition.children &&
                definition.children.map(childId => <GUIComponent key={childId} componentId={childId}></GUIComponent>)
            }
        </>
    )
}

export default Container