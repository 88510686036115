import { TextInput } from 'react-native'
import React, { useEffect } from 'react'

const Input = ({ definition, style }) => {
    const [input, setInput] = useState(definition.properties?.input?.default || '')

    // useEffect(() => {
    //     setInput(definition.properties?.input?.default || '')
    // }, [definition.properties?.input?.default])

    return (
        <TextInput
            style={styles.input}
            onChangeText={setInput}
            value={input}
            placeholder={definition.properties?.input?.default}
        />
    )
}

export default Input
