import { View, Pressable } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from './componentsLibrary/Button'
import Text from './componentsLibrary/Text'
import Image from './componentsLibrary/Image'
import Container from './componentsLibrary/Container'
import Input from './componentsLibrary/Input'

import { computeStyle } from '../services/styling'

const getComponent = (componentDef, componentStyle) => {
    switch (componentDef.type) {
        // TODO: generalize component import and usage (e.g. loop through all components in componentsLibrary folder)
        case 'FloatingContainer':
            console.log('TODO: floating group')
        case 'Container':
            // case 'CustomDefinition':
            return <Container definition={componentDef} style={componentStyle} />
        // case 'ListContainer':
        //     return <RepeatingGroup definition={componentDef} style={componentStyle} />
        case 'Text':
            return <Text definition={componentDef} style={componentStyle} />
        case 'Image':
            return <Image definition={componentDef} style={componentStyle} />
        // case 'Input':
        //     return <Input definition={componentDef} style={componentStyle} />
        case 'Button':
            return <Button definition={componentDef} style={componentStyle} />
        case 'Input':
            return <Input definition={componentDef} style={componentStyle} />
        // case 'Icon':
        //     return <Icon definition={componentDef} style={componentStyle} />
        // case 'CustomElement':
        //     return <CustomElement definition={componentDef} style={componentStyle} />
        // case 'HTML':
        //     return <HTML definition={componentDef} style={componentStyle} />
        case 'Popup':
            console.log('TODO: popup')
            return null
        default:
            console.error('Unknown component type:', componentDef.type)
            return null
    }
}
const GUIComponent = ({ componentId }) => {
    const dispatch = useDispatch()

    const componentDef = useSelector(state => state.project.definition.components[componentId])
    const parentDef = useSelector(state => state.project.definition.components[componentDef.parent])

    // return nothing if hidden
    if (componentDef.properties?.is_hidden === false) {
        return null
    }

    // handle variations
    // let variationStyle = {};
    // if (componentDef.logic && componentDef.logic.length > 0) {
    //     // console.log('Variations:', componentDef.get('variations'))
    //     variationStyle = componentDef.logic.reduce(
    //         (acc, variation) => {
    //             if (variation?.condition) {
    //                 const condition = useEvaluate(variation.condition);
    //                 if (condition) {
    //                     acc = { ...acc, ...variation.success.toJS() };
    //                 }
    //             } else {
    //                 console.error('Error? (expected a condition)', variation)
    //             }
    //             return acc;
    //         },
    //         variationStyle,
    //     )
    //     // for (let index = 0; index < componentDef.get('variations', []).size; index++) {
    //     //   const variation = array[index];

    //     // }
    // }
    // const componentStyle = componentDef.get('style', {}).toJS();
    const computedStyle = computeStyle(componentDef, parentDef)
    const componentStyle = {
        // ...style,
        // ...componentStyle,
        ...computedStyle,
        // ...variationStyle
    };

    const parseUnit = (value) => {
        if (typeof value === 'string' && value.endsWith('%')) {
            return parseFloat(value.substring(0, value.length - 2))
        }
        return value
    }

    return (
        <Pressable style={componentStyle} disabled onPress={() => { }}>
            {getComponent(componentDef, componentStyle)}
        </Pressable>
    )
}

export default GUIComponent