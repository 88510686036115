import React from 'react'
import { Provider } from 'react-redux';
import store from './src/store';
import { Navigation } from './src/Navigation';
import { EventProvider } from 'react-native-outside-press';


export default function App() {
    return (
        <Provider store={store}>
            <EventProvider style={{ flex: 1 }}>
                <Navigation></Navigation>
            </EventProvider>
        </Provider>
    );
}
