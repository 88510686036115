export const borderOptions = ['none', 'solid', 'dotted', 'dashed', 'double', 'groove', 'ridge', 'inset', 'outset']

export function computeStyle(itemDefinition, parentDefinition) {
    const styles = [
        widthStyle,
        heightStyle,
        marginStyle,
        paddingStyle,
        borderStyle,
        radiusStyle,
        layoutStyle,
        positionStyle,
        backgroundStyle,
        textStyle,
    ].reduce(
        (acc, styleOperation) => styleOperation(acc, itemDefinition, parentDefinition),
        {}
    );
    return styles;
}

function parseVal(dictValue = {}, componentDef, debug) {
    // TODO find a way to get design system instead of componentDef
    // Should create a hook?
    try {
        switch (dictValue.unit || 'rf') {
            case 'px':
                return `${dictValue.val || 0}${dictValue.unit || 'px'}`
            case '%':
                return `${dictValue.val || 0}${dictValue.unit || '%'}`
            case 'rf':
                // TODO replace 12 with design system value (see TODO above)
                return (dictValue.val || 0) * (componentDef.design_system?.responsive_factor?.desktop || 12)
            default:
                break;
        }
    } catch (error) {
        console.warn(`Couldn't parse value: ${JSON.stringify(dictValue)}`, debug, '\n---', error.message)
    }
}
function widthStyle(accStyles, props, parentDefinition) {
    const styles = { ...accStyles }
    switch (props.custom_styling?.width?.type || 'expand') {
        case 'fixed': {
            styles.width = parseVal(props.custom_styling?.width.fixed, props, `${props.uid}: [width]`)
            return styles;
        }
        case 'expand': {
            if (parentDefinition?.custom_styling?.layout?.auto?.direction === 'column') {
                styles.alignSelf = 'stretch'
            } else if (parentDefinition?.custom_styling?.layout?.type === 'relative') {
                styles.width = '100%'
            } else {
                styles.flexGrow = 1
                // styles.flexShrink = 1
            }
            break;
        }
        case 'fit': {
            styles.width = 'fit-content'
            break;
        }
        default:
            break;
    }
    if (props.custom_styling?.width?.min?.val) {
        styles.minWidth = parseVal(props.custom_styling?.width.min, props, `${props.uid}: [min-width]`)
    }
    if (props.custom_styling?.width?.max?.val) {
        styles.maxWidth = parseVal(props.custom_styling?.width.max, props, `${props.uid}: [max-width]`)
    }
    return styles
}
function heightStyle(accStyles, props, parentDefinition) {
    const styles = { ...accStyles }
    switch (props.custom_styling?.height?.type || 'fit') {
        case 'fixed': {
            styles.height = parseVal(props.custom_styling?.height.min || {}, props, `${props.uid}: [fixed-width]`)
            return styles;
        }
        case 'expand': {
            if (parentDefinition?.custom_styling?.layout?.auto?.direction === 'row') {
                styles.alignSelf = 'stretch'
            } else if (parentDefinition?.custom_styling?.layout?.type === 'relative') {
                styles.height = '100%'
            } else {
                styles.flexGrow = 1
                styles.flexShrink = 1
            }
            break;
        }
        case 'fit': {
            styles.height = 'fit-content'
            break;
        }
        case 'ratio': {
            styles.aspectRatio =
                (parentDefinition?.custom_styling?.height?.ratio?.height || 1) + ' / ' +
                (parentDefinition?.custom_styling?.height?.ratio?.width || 1);
            break;
        }
        default:
            break;
    }
    if (props.custom_styling?.height?.min?.val) {
        styles.minHeight = parseVal(props.custom_styling?.height.min, props)
    }
    if (props.custom_styling?.height?.max?.val) {
        styles.maxHeight = parseVal(props.custom_styling?.height.max, props)
    }
    return styles
}
function marginStyle(accStyles, props) {
    const styles = { ...accStyles }
    switch (props.custom_styling?.margin?.type) {
        case 'unified': {
            styles.margin = parseVal(props.custom_styling?.margin?.all || {}, props, `${props.uid}: [margin]`)
            break;
        }
        case 'individual': {
            styles.marginTop = parseVal(props.custom_styling?.margin?.top || {}, props, `${props.uid}: [margin-top]`)
            styles.marginLeft = parseVal(props.custom_styling?.margin?.left || {}, props, `${props.uid}: [margin-left]`)
            styles.marginRight = parseVal(props.custom_styling?.margin?.right || {}, props, `${props.uid}: [margin-right]`)
            styles.marginBottom = parseVal(props.custom_styling?.margin?.bottom || {}, props, `${props.uid}: [margin-bot]`)
            break;
        }
        default:
            // console.warn(`Unknown margin type: ${props.custom_styling?.margin?.type}`)
            break;
    }
    return styles
}
function paddingStyle(accStyles, props) {
    const styles = { ...accStyles }
    switch (props.custom_styling?.padding?.type) {
        case 'unified': {
            styles.padding = parseVal(props.custom_styling?.padding?.all || {}, props, `${props.uid}: [width]`)
            break;
        }
        case 'individual': {
            styles.paddingTop = parseVal(props.custom_styling?.padding?.top || {}, props, `${props.uid}: [padding - top]`)
            styles.paddingLeft = parseVal(props.custom_styling?.padding?.left || {}, props, `${props.uid}: [padding - left]`)
            styles.paddingRight = parseVal(props.custom_styling?.padding?.right || {}, props, `${props.uid}: [padding - right]`)
            styles.paddingBottom = parseVal(props.custom_styling?.padding?.bottom || {}, props, `${props.uid}: [padding - bot]`)
            break;
        }
        default:
            // console.warn(`Unknown padding type: ${props.custom_styling?.padding?.type}`)
            break;
    }
    return styles
}
function borderStyle(accStyles, props) {
    const styles = { ...accStyles }
    switch (props.custom_styling?.border?.type || 'none') {
        case 'unified': {
            if (props.custom_styling?.border?.all?.style || 'none' !== 'none') {
                styles.borderWidth = parseVal(props.custom_styling?.border?.all?.width || {}, props, `${props.uid}: [border - width]`)
                styles.borderStyle = props.custom_styling?.border?.all?.style || 'solid'
                styles.borderColor = props.custom_styling?.border?.all?.color || '#000'
            }
            break;
        }
        case 'individual': {
            if ((props.custom_styling?.border?.top?.style || 'none') !== 'none') {
                styles.borderTopWidth = parseVal(props.custom_styling?.border?.top?.width || {}, props, `${props.uid}: [border-top - width]`)
                styles.borderTopStyle = props.custom_styling?.border?.top?.style || 'solid'
                styles.borderTopColor = props.custom_styling?.border?.top?.color || '#000'
            }
            if ((props.custom_styling?.border?.left?.style || 'none') !== 'none') {
                styles.borderLeftWidth = parseVal(props.custom_styling?.border?.left?.width || {}, props, `${props.uid}: [border-left - width]`)
                styles.borderLeftStyle = props.custom_styling?.border?.left?.style || 'solid'
                styles.borderLeftColor = props.custom_styling?.border?.left?.color || '#000'
            }
            if ((props.custom_styling?.border?.right?.style || 'none') !== 'none') {
                styles.borderRightWidth = parseVal(props.custom_styling?.border?.right?.width || {}, props, `${props.uid}: [border-right - width]`)
                styles.borderRightStyle = props.custom_styling?.border?.right?.style || 'solid'
                styles.borderRIghtColor = props.custom_styling?.border?.right?.color || '#000'
            }
            if ((props.custom_styling?.border?.bottom?.style || 'none') !== 'none') {
                styles.borderBottomWidth = parseVal(props.custom_styling?.border?.bottom?.width || {}, props, `${props.uid}: [border-bottom - width]`)
                styles.borderBottomStyle = props.custom_styling?.border?.bottom?.style || 'solid'
                styles.borderBottomColor = props.custom_styling?.border?.bottom?.color || '#000'
            }
            break;
        }
        default:
            // console.warn(`Unknown border type: ${props.custom_styling?.border?.type}`)
            break;
    }
    return styles
}
function radiusStyle(accStyles, props) {
    const styles = { ...accStyles }
    // switch (props.custom_styling?.radius?.type) {
    //     case 'unified': {
    //         styles.borderRadius = parseVal(props.custom_styling?.radius.all || {}, props)
    //         break;
    //     }
    //     case 'individual': {
    styles.borderTopLeftRadius = parseVal(props.custom_styling?.radius?.top_left || {}, props)
    styles.borderTopRightRadius = parseVal(props.custom_styling?.radius?.top_right || {}, props)
    styles.borderBottomLeftRadius = parseVal(props.custom_styling?.radius?.bottom_left || {}, props)
    styles.borderBottomRightRadius = parseVal(props.custom_styling?.radius?.bottom_right || {}, props)
    if (styles.borderTopLeftRadius || styles.borderTopRightRadius || styles.borderBottomLeftRadius || styles.borderBottomRightRadius) {
        styles.overflow = 'hidden'
    }
    //         break;
    //     }
    //     default:
    //         break;
    // }
    return styles
}
function layoutStyle(accStyles, props) {
    const distribution = {
        'space-evenly': 'space-evenly',
        'space-between': 'space-between',
        'around': 'space-around',
        'start': 'flex-start',
        'center': 'center',
        'end': 'flex-end',
    }
    const styles = { ...accStyles }
    switch (props.custom_styling?.layout?.type || 'auto') {
        case 'auto': {
            styles.display = 'flex'
            styles.flexShrink = 1
            styles.flexWrap = 'wrap'
            styles.flexDirection = props.custom_styling?.layout?.auto?.direction || 'column'
            if (props.custom_styling?.layout?.auto?.distribution) {
                styles.justifyContent = distribution[props.custom_styling?.layout.auto.distribution]
            }
            // if (props.custom_styling?.layout.auto.contentPosition) {
            //     const position = props.custom_styling?.layout.auto.contentPosition
            //         .replace(/top|left/gi, "start")
            //         .replace(/bottom|right/gi, "end")
            //         .replace('-', " ");
            //     styles.alignContent = position
            // }
            if (props.custom_styling?.layout?.auto?.row_gap) {
                styles.rowGap = parseVal(props.custom_styling?.layout?.auto?.row_gap || {}, props, 'rowGap')
                console.log('rowGap', styles.rowGap)
            }
            if (props.custom_styling?.layout?.auto?.column_gap) {
                styles.columnGap = parseVal(props.custom_styling?.layout?.auto?.column_gap || {}, props, 'columnGap')
                console.log('columnGap', styles.columnGap)
            }
            break;
        }
        case 'manual': {
            styles.position = 'relative'
            break;
        }
        case 'relative': {
            styles.display = 'grid';
            styles.gridTemplateRows = '100%';
            styles.gridTemplateColumns = '100%';
            break;
        }
        default:
            break;
    }
    return styles
}
function positionStyle(accStyles, props, parentProps) {
    const styles = { ...accStyles }
    if (!parentProps) {
        return styles;
    }
    const parentLayout = parentProps.custom_styling?.layout?.type || 'auto'
    switch (parentLayout) {
        case 'auto': {
            const toFlex = {
                'start': 'flex-start',
                'center': 'center',
                'end': 'flex-end',
            }
            if (props.custom_styling?.position?.auto?.order) {
                styles.order = props.custom_styling?.position?.auto?.order
            }
            if (props.custom_styling?.position?.auto?.position) {
                styles.flexGrow = 0
                styles.flexShrink = 0
                styles.alignSelf = props.custom_styling?.position.auto.position
            }
            if (parentProps.direction === 'row') {
                if ((props.custom_styling?.height?.type || 'fit') === 'fit') {
                    styles.alignSelf = toFlex[props.custom_styling?.position?.auto?.vertical || 'start']
                }
                // const heightType = props.custom_styling?.height?.type || 'fit'
                // styles.alignSelf = 'flex-' + heightType !== 'expand'
                //     ? (props.custom_styling?.position?.auto?.vertical || 'start')
                //     : 'stretch'
            } else {
                if ((props.custom_styling?.width?.type || 'expand') === 'fit') {
                    styles.alignSelf = toFlex[props.custom_styling?.position?.auto?.horizontal || 'start']
                }
                // const widthType = props.custom_styling?.width?.type || 'expand'
                // styles.alignSelf = 'flex-' + widthType !== 'expand'
                //     ? (props.custom_styling?.position?.auto?.horizontal || 'start')
                //     : 'stretch'
            }
            break;
        }
        case 'relative': {
            styles.position = 'absolute'
            const widthType = props.custom_styling?.width?.type || 'expand'
            const heightType = props.custom_styling?.height?.type || 'fit'
            const horizontalPosition = widthType !== 'expand'
                ? (props.custom_styling?.position?.relative?.vertical || 'start')
                : 'stretch'
            const verticalPosition = heightType !== 'expand'
                ? (props.custom_styling?.position?.relative?.horizontal || 'start')
                : 'stretch'
            styles.placeSelf = verticalPosition + ' ' + horizontalPosition
            break;
        }
        case 'manual': {
            styles.position = 'absolute'
            if (props.custom_styling?.position?.manual?.top) {
                const top = parseVal(props.custom_styling?.position?.manual?.top, props, `${props.uid}: position [top]`)
                styles.top = top
            }
            if (props.custom_styling?.position?.manual?.left) {
                styles.left = parseVal(props.custom_styling?.position?.manual?.left, props, `${props.uid}: position [left]`)
            }
            if (props.custom_styling?.position?.manual?.right) {
                styles.right = parseVal(props.custom_styling?.position?.manual?.right, props, `${props.uid}: position [right]`)
            }
            if (props.custom_styling?.position?.manual?.bottom) {
                styles.bottom = parseVal(props.custom_styling?.position?.manual?.bottom, props, `${props.uid}: position [bottom]`)
            }
            // translation for centered items
            if (styles.top === '50%' && styles.left === '50%') {
                styles.transform = 'translate(-50%, -50%)'
            } else if (styles.top === '50%') {
                styles.transform = 'translateY(-50%)'
            } else if (styles.left === '50%') {
                styles.transform = 'translateX(-50%)'
            }
            break;
        }
        default:
            break;
    }
    return styles
}
function backgroundStyle(accStyles, props) {
    const styles = { ...accStyles }
    switch (props.custom_styling?.background?.type || 'none') {
        case 'none': {
            break;
        }
        case 'color': {
            styles.backgroundColor = props.custom_styling?.background.color
            break;
        }
        case 'image': {
            styles.backgroundImage = `url(${props.custom_styling?.background?.image?.src})`
            styles.backgroundRepeat = 'no-repeat'
            styles.backgroundSize = 'cover'
            styles.backgroundPosition = 'center center'
            break;
        }
        case 'gradient': {
            const colors = props.custom_styling?.background?.gradient?.colors || ['ffffff']
            const strColors = colors.join(', ')
            switch (props.custom_styling?.background.gradient?.type) {
                case 'linear': {
                    const direction = props.custom_styling.background.gradient.direction
                    styles.background = colors[0]
                    const gradient = `gradient(${direction}, ${strColors})`
                    styles.background = `-moz-linear-${gradient}`
                    styles.background = `-webkit-linear-${gradient}`
                    styles.background = `linear-${gradient}`
                    break;
                }
                case 'radial': {
                    const shape = props.custom_styling.background.gradient.shape || 'circle'
                    const gradient = `gradient(${strColors})`
                    styles.background = colors[0]
                    styles.background = `-moz-radial-${gradient}`
                    styles.background = `-webkit-radial-${gradient}`
                    styles.background = `radial-${gradient}`
                    break;
                }
                default:
                    console.log('Unknown gradient type:', props.custom_styling?.background?.type)
                    break;
            }
            break;
        }
        default:
            break;
    }
    return styles
}

function textStyle(accStyles, props) {
    const styles = { ...accStyles }
    styles.text = {}

    if (props.custom_styling?.text?.color) {
        styles.text.color = props.custom_styling?.text.color
    }
    if (props.custom_styling?.text?.alignment) {
        styles.text.textAlign = props.custom_styling?.text.alignment
    }
    if (props.custom_styling?.text?.size) {
        styles.text.fontSize = parseVal(props.custom_styling?.text.size, props, `${props.uid}: text [size]`)
    }
    if (props.custom_styling?.text?.font) {
        styles.text.fontFamily = props.custom_styling?.text.font
    }
    if (props.custom_styling?.text?.height) {
        styles.text.fontHeight = parseVal(props.custom_styling?.text.height, props, `${props.uid}: text [height]`)
    }
    if (props.custom_styling?.text?.weight) {
        styles.text.fontWeight = props.custom_styling?.text.weight
    }
    if (props.custom_styling?.text?.shadow) {
        const color = props.custom_styling?.text.shadow.color || 'rgb(0,0,0,0)'
        const horizontal = parseVal(props.custom_styling?.text.shadow.horizontal, props, `${props.uid}: text [shadow-horiz]`)
        const vertical = parseVal(props.custom_styling?.text.shadow.vertical, props, `${props.uid}: text [shadow-vert]`)
        const blur = parseVal(props.custom_styling?.text.shadow.blur, props, `${props.uid}: text [shadow-blur]`)
        styles.text.textShadow = `${color} ${horizontal}px ${vertical}px ${blur}px`
        console.log('text shadow', styles.text.textShadow)
    }

    return styles;
}
