import { StyleSheet, Text } from 'react-native'
import React from 'react'

const Button = ({ definition, style }) => {
    return (
        <Text>{definition.properties?.text || ''}</Text>
    )
}

export default Button

const styles = StyleSheet.create({})