import { Image as _Image } from 'react-native'
import React from 'react'


const Image = ({ definition, style }) => {
    if (!definition?.properties?.image) {
        return null
    }
    return (
        <_Image
            id={definition.uid}
            style={[{ flex: 1, objectFit: 'cover', }]}
            source={{ uri: definition.properties.image }}
        />
    )
}

export default Image
