import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './UserSlice';
import projectSlice from './ProjectSlice';

// Create the Redux store
const store = configureStore({
    reducer: {
        user: userReducer,
        project: projectSlice,
        // Add other reducers here if needed
    },
    middleware: [thunk],
});

export default store;
