import { Text as _Text, Pressable } from 'react-native'
import React from 'react'
import { useDispatch } from 'react-redux'

const Text = ({ definition, style }) => {
    const dispatch = useDispatch()

    return (
        // TODO fix behavior of text component when long text without spaces (no line wrap)
        <_Text
            id={definition.uid}
            style={[style.text, { flex: 1 }]}
        >
            {definition.properties?.text || ''}
        </_Text>
    )
}

export default Text
