import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useNavigationState } from '@react-navigation/native';

import GUIComponent from '../components/GUIComponent';

const Page = ({ route }) => {
    const dispatch = useDispatch()

    const { pageId } = route.params;

    const navigation = useNavigation();
    const routes = useNavigationState(state => state.routes);
    const pageName = useNavigationState(state => state.routeName);

    const projectDef = useSelector(state => state.project.definition)

    useEffect(() => {
        const handleResize = () => {
            const { width } = Dimensions.get('window');
            setScreenSize(width < 768 ? 'small' : 'large');
        };
        Dimensions.addEventListener('change', handleResize);

        return () => {
            Dimensions.removeEventListener('change', handleResize);
        };
    }, []);

    return (
        <View style={styles.container}>
            <GUIComponent componentId={projectDef.pages[pageId].root_component}></GUIComponent>
        </View>
    );

};

export default Page;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    toggleButton: {
        position: 'absolute',
        top: 16,
        left: 16,
        zIndex: 2, // Ensure the button is displayed above the overlays
    },
    middleContent: {
        flex: 1,
        borderWidth: 10,
        borderColor: '#f9f9f9',
    },
    leftPanel: {
        maxWidth: 240,
        borderRightWidth: 1,
        backgroundColor: '#ffffff',
        padding: 4,
    },
    rightPanel: {
        maxWidth: 300,
    },
});


