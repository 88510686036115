import { createSlice } from '@reduxjs/toolkit';
import { realtimeDB } from '../services/firebase';

// Thunk Actions
// export const fetchProject = (projectDomain) => async dispatch => {
//     return unsubscribe
// }

const projectSlice = createSlice({
    name: 'project',
    initialState: { definition: {}, projectId: '' },
    reducers: {
        setProjectDefinition: (state, action) => {
            return { ...state, definition: action.payload };
        },
        setProjectId: (state, action) => {
            return { ...state, projectId: action.payload };
        }
    }
});

export default projectSlice.reducer;
export const { setProjectDefinition, setProjectId } = projectSlice.actions
